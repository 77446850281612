import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { BiEnvelope } from "react-icons/bi";
import { FiFacebook } from "react-icons/fi";
import Logo from "./../images/Logo.svg";
import Menu from "./Menu";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {getData} from "../action"
import {connect} from 'react-redux'

function Header(props) {

  const handleScroll = () => {
    const header = document.getElementById("header");
    const position = window.pageYOffset;
    if (position > 0) {
      header.classList.add("show");
    } else {
      header.classList.remove("show");
    }
  };

  // i18
  let lang = localStorage.getItem("i18nextLng");
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };
  const [active, setActive] = useState("az");
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    setActive(lang);
    props.getData();
  }, [lang]);

  const menuClick = (e) => {
    const menu = document.getElementById("menu");
    const header = document.getElementById("header");
    e.preventDefault();
    if (!menu.classList.contains("show")) {
      header.classList.add("show-header");
      menu.classList.add("show");
    } else {
      menu.classList.remove("show");
      header.classList.remove("show-header");
    }
  };
  return (
    <header className={props.className} id="header">
      <div className="header-top">
        {props.data.Contact &&
          <Container>
            <div>
              <a href={`tel:${props.data.Contact[0].phone1}`}>{props.data.Contact[0].phone1}</a>
              <p> {t("unvan")}</p>
            </div>
            <div>
              <a href={`mailto:${props.data.Contact[0].email}`} className="mail">
                <BiEnvelope />
              </a>
              <a href={props.data.Contact[0].instalink} className="insta">
                <BsInstagram />
              </a>
              <a href={props.data.Contact[0].fblink} className="fb">
                <FiFacebook />
              </a>
            </div>
          </Container>
        }
      </div>
      <div className="header-bottom">
        <Container>
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
          <div className="header-right">
            <ul className="lang">
              <li onClick={(e) => changeLanguage("az")} className={active === "az" ? "active" : ""}>Az </li>
              <li onClick={(e) => changeLanguage("en")} className={active === "en" ? "active" : ""}>En</li>
              <li onClick={(e) => changeLanguage("ru")} className={active === "ru" ? "active" : ""}>Ru</li>
            </ul>
            <div className="menu-btn-div" onClick={menuClick}>
              <div className="menu-btn"></div>
            </div>
          </div>
        </Container>
      </div>
      {props.data.Contact && <Menu data={props.data.Contact[0]} />}
    </header>
  );
}

const mapStateToProps = state => {
  return {
    data: state.data,
  }
}
export default connect(mapStateToProps, {getData})(Header)
