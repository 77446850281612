import React, { Fragment, useEffect } from 'react'
import Breadcrumbs from '../Components/Breadcrumbs'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import SingleNews from '../Components/SingleNews'
import { useTranslation } from 'react-i18next'
import {useParams} from "react-router-dom"
import {getData} from "../action"
import {connect} from 'react-redux'
function NewsSinglePage(props) {
  const {productId} = useParams()
  const {t} = useTranslation()
  useEffect(() => {
    props.getData()

}, [])


const thisProduct = props.data.Xeberler?.find(prod => prod.id === +productId)
  return (
    <Fragment>
       <Header/>
        {/* {props.data.Contact && <Header data={props.data.Contact}/>} */}
        {thisProduct && 
          <Breadcrumbs id={'breadcrumbs-news-single'} bred2={t('breadcrumb-xeberler')} title={thisProduct.title} bred={thisProduct.title} bredLink={'news'}/>
        }
        <SingleNews data={thisProduct}/>
        {props.data.Contact && <Footer data={props.data.Contact}/>}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    data: state.data
  }
}
export default connect(mapStateToProps, {getData})(NewsSinglePage)