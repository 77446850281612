import React from 'react'
import {Container} from 'reactstrap'
import {BsInstagram} from 'react-icons/bs'
import {BiEnvelope} from 'react-icons/bi'
import {FiFacebook} from 'react-icons/fi'
import {useTranslation} from 'react-i18next'
function VacancyContact(props) {
    const {t} = useTranslation()
  return (
    <section className='vacancy-contact-page'>
        <div id="vacancy-contact">
            {props.data[0] && 
                <Container>
                    <h2 className='title'>{t("unvan-title")}</h2>
                    <div className='vacancy-contact-text'>
                        <p>{t("unvan")}</p>
                        <div className='contact-phone'>
                            <a href={`tel:${props.data[0].phone1}`}>{props.data[0].phone1}</a>
                            <a href={`https://wa.me/${props.data[0].phone2}`}>{props.data[0].phone2}</a>
                            <a href={`mailto:${props.data[0].email}`}>{props.data[0].email}</a>
                        </div>
                    </div>
                    <div>
                        <h3>{t("unvan-title-2")}</h3>
                        <div className='social-link'>
                            <a href={`mailto:${props.data[0].email}`} className='mail'><BiEnvelope/> {t("email")}</a>
                            <a href={props.data[0].instalink} className='insta'><BsInstagram/> {t("instagram")}</a>
                            <a href={props.data[0].fblink} className='fb'><FiFacebook/> {t("facebook")}</a>
                        </div>
                    </div>
                </Container>
            }
        </div>
    </section>
  )
}


export default VacancyContact