import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import {FreeMode,Autoplay,  Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; 

import { useTranslation } from 'react-i18next';
import {Link } from 'react-router-dom'

function Home(props) {
  const {t} = useTranslation()
  
  return (
    <section id='home'>
        <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={true}
        loop={true}
        modules={[FreeMode, Autoplay, Pagination, Navigation]}
       
        className="mySwiper"
      >
        {props.data && props.data.map((item, index)=>(
          <SwiperSlide key={index}>
            <img src={item.image} alt='' className='slider-img'/>
            <div className='slider-text'>
              <p className='slider-subtitle'>{item.desc}</p>
              <h2 className='slider-title'>{item.title}</h2>
              <Link to={`${item.link}`} className='button'>{t("home-button")}</Link>
            </div>
            {/* <div className='pagination-div'><span className='pagination'>* 0{index+1}</span></div> */}
            </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}


export default Home