import React , {useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {Container} from 'reactstrap'
import {BsFillPlayFill} from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs'
import {MdClose} from 'react-icons/md'
import Iframe from "react-iframe";

function Gallery(props) {
  const {t} = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const photoRef = useRef();
  const videoRef = useRef();
  const videoLinkRef = useRef();
  const photoLinkRef = useRef();

  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [clickedVideo, setClickedVideo] = useState(null);


  const activeFunc = () => {
    setIsActive(current => !current);
    if(photoRef.current.style.display === 'none'){
      photoRef.current.style.display = 'block';
      videoRef.current.style.display = 'none';
      videoLinkRef.current.style.color= '#909090';
      photoLinkRef.current.style.color= 'black';

    }else{
      photoRef.current.style.display = 'none';
      videoRef.current.style.display = 'block';
      videoLinkRef.current.style.color= 'black';
      photoLinkRef.current.style.color= '#909090';
    }
  }
 
    
      const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.image);
        setClickedVideo(item.link)
      }
    
      const handleRotationLeft = () => { 
        const totalLength = props.dataImg.length;
        if (currentIndex === 0) {
          setCurrentIndex(totalLength - 1);
          const newUrl = props.dataImg[totalLength - 1].image;
          setClickedImg(newUrl);
          return;
        }
    
        const newIndex = currentIndex - 1;
        const newUrl = props.dataImg.filter((item) => {
          return props.dataImg.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].image;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        
      };
      
      const handleRotationRight = () => {
        const totalLength = props.dataImg.length;
    
        if(currentIndex +1 >= totalLength){
          setCurrentIndex(0);
          const newUrl = props.dataImg[0].image;
          setClickedImg(newUrl);
          return;
        }
    
        const newIndex = currentIndex + 1;
        const newUrl = props.dataImg.filter((item) => {
          return props.dataImg.indexOf(item) === newIndex;
        });
    
        const newItem = newUrl[0].image;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
      }
    
      const handleDismissClick = (e) => {
        if (e.target.classList.contains("dismiss")) {
          setClickedImg(null);
          setClickedVideo(null)
        }
      };

  
      
    
    
 
  return (
    <section id="gallery">
        <div className="about-header">
          <Container>
            <Link to="#photo" id="photoLink" onClick={activeFunc} ref={photoLinkRef}>{t("fotoqalereya")}</Link>
            <Link to="#video" id="videoLink" onClick={activeFunc} ref={videoLinkRef}>{t("videoqalereya")}</Link>
          </Container>
        </div>
        <div id="photo" className='gal' ref={photoRef}>
          
          {props.dataImg && 
            <Container>
              <div className='gallery-img-1'>
                <img src={props.dataImg[0].image} alt="" onClick={() => handleClick(props.dataImg[0],props.dataImg[0].id )}/>
              </div>
              <div className='gallery-images'>
                {props.dataImg.slice(1,7).map((item, index)=>(
                  <div className='gallery-img' key={item.id}>
                    <img src={item.image} alt="" onClick={() => handleClick(item,item.id )}/>
                  </div>
                ))}
              </div>
              <div className='gallery-images'>
                {props.dataImg.slice(7,13).map(item=>(
                  <div className='gallery-img' key={item.id}>
                    <img src={item.image} alt="" onClick={() => handleClick(item,item.id )}/>
                  </div>
                ))}
              </div>
              <div className='gallery-img-1'>
                <img src={props.dataImg[13].image} alt="" onClick={() => handleClick(props.dataImg[13],props.dataImg[13].id )}/>
              </div>
            </Container>
              
          }
        </div> 
        <div id="video" className='gal' ref={videoRef}>
          <Container>
            {props.dataVideo && props.dataVideo.map(item=>(
              <div className='gallery-video' key={item.id}>
                <img src={item.image} alt="" onClick={()=> handleClick(item, item.id)}/>
                <div className='play'><BsFillPlayFill/></div>
              </div>
            ))}
          </Container>
        </div>

        {/* Lightbox */}

        {clickedImg && (
          <div className='lightbox'>
            <div className='dismiss' onClick={handleDismissClick}>
              <div><MdClose className='dismiss dismiss-btn' onClick={handleDismissClick}/></div>
              <div className="left-btn" onClick={handleRotationLeft}><BsChevronLeft/></div>
              <div className="lightbox-box">
                <img src={clickedImg} alt=""/>
              </div>
              <div className="right-btn" onClick={handleRotationRight}><BsChevronRight/></div>
            </div>
          </div>
        )}

        {clickedVideo && 
          <div className='lightbox'>
            <div className='dismiss' onClick={handleDismissClick}>
              <div><MdClose className='dismiss dismiss-btn' onClick={handleDismissClick}/></div>
              <div className="lightbox-box">
              <Iframe src={clickedVideo}
              width="100%"
              height="100%"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
              </div>
            </div>
          </div>
        }

    </section>
  )
}


export default Gallery