const initialState = {
    data: [],
    message: ''
}

export const reducer = (state = initialState, action ) => {
    switch(action.type){
        case 'success' : return {...state, data: action.payload}
        case 'error' : return {...state, message: action.payload }
        default: return state
    }
}