import React from 'react'
import {NavLink } from 'react-router-dom'
import {Container} from 'reactstrap'
import {BsInstagram} from 'react-icons/bs'
import {BiEnvelope} from 'react-icons/bi'
import {FiFacebook} from 'react-icons/fi'
import {useTranslation} from 'react-i18next'


function Menu(props) {
    const {t} = useTranslation();
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
  return (
    <nav id='menu'>
        <Container>
            <ul>
                <li><NavLink to="/about" className={({ isActive }) => isActive? "active": ''} onClick={ClickHandler}>{t("menu-1")}</NavLink></li>
                <li><NavLink to="/products" onClick={ClickHandler}>{t("menu-2")}</NavLink></li>
                <li><NavLink to="/gallery" onClick={ClickHandler}>{t("menu-3")}</NavLink></li>
                <li><NavLink to="/news" onClick={ClickHandler}>{t("menu-4")}</NavLink></li>
                <li><NavLink to="/vacancy" onClick={ClickHandler}>{t("menu-5")}</NavLink></li>
                <li><NavLink to="/contact" onClick={ClickHandler}>{t("menu-6")}</NavLink></li>
            </ul>
            {props.data && 
                <div className='menu-container'>
                    <h2 className='title'>{t("menu-title")}</h2>
                    <p className='menu-subtitle'>{t("menu-subtitle")}</p>
                    <div className='menu-contact'>
                        <a href={`tel:${props.data.phone1}`}> {props.data.phone1} </a>
                        <p>{t('unvan')}</p>
                        <p>{t('unvan-2')}</p>
                    </div>
                    <div className='social-link'>
                        <a href={`mailto:${props.data.email}`} className='mail'><BiEnvelope/></a>
                        <a href={props.data.instalink} className='insta'><BsInstagram/></a>
                        <a href={props.data.fblink} className='fb'><FiFacebook/></a>
                    </div>
                </div>
            }
 
        </Container>
    </nav>
  )
}

export default Menu