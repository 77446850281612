import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import { BsInstagram } from 'react-icons/bs'
import { BiEnvelope } from 'react-icons/bi'
import { FiFacebook } from 'react-icons/fi'
// import Logo from './../images/logo.svg'
import Jedai from './../images/jedai.png'
import { Link } from 'react-router-dom'
import { RiWhatsappLine } from 'react-icons/ri'
import { IoIosArrowUp } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

function Footer(props) {
    const { t } = useTranslation()
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <footer>
            <Container>
                <Row>
                    <Col xl='6' lg="6" md="12">
                        <ul>
                            <li><Link to="/about" onClick={ClickHandler}>{t('menu-1')}</Link></li>
                            <li><Link to="/products" onClick={ClickHandler}>{t('menu-2')}</Link></li>
                            <li><Link to="/gallery" onClick={ClickHandler}>{t('menu-3')}</Link></li>
                            <li><Link to="/news" onClick={ClickHandler}>{t('menu-4')}</Link></li>
                            <li><Link to="/vacancy" onClick={ClickHandler}>{t('menu-5')}</Link></li>
                            <li><Link to="/contact" onClick={ClickHandler}>{t('menu-6')}</Link></li>
                        </ul>
                    </Col>
                    <Col xl='6' lg="6" md="12" className='footer-contact'>
                        <p>{t("unvan")}</p>
                        <p>{t('unvan-2')}</p>
                        {props.data[0] &&
                            <div>
                                <div className='contact'>
                                    <a href={`tel:${props.data[0].phone1}`}>{props.data[0].phone1}</a>
                                </div>
                                <a href={`mailto:${props.data[0].email}`}>{props.data[0].email}</a>
                            </div>
                        }
                    </Col>
                </Row>
                <div className='footer-bottom'>
                    {/* <Link to="/"><img src={Logo} alt=""/></Link> */}
                    <div></div>
                    <div className='social-link'>
                        <p>{t("unvan-title-3")}</p>
                        {props.data[0] &&
                            <div>
                                <a href={`mailto:${props.data[0].email}`} className='mail'><BiEnvelope /></a>
                                <a href={props.data[0].instalink} className='insta'><BsInstagram /></a>
                                <a href={props.data[0].fblink} className='fb'><FiFacebook /></a>
                            </div>
                        }
                    </div>
                </div>
                <div className='copyright'>
                    <p>{t("copyright")}</p>
                    {/* <a href="https://jedai.az/az/saytlarin-hazirlanmasi" target="blank" >Site By <img src={Jedai} alt="" /></a> */}
                </div>
            </Container>
            {props.data[0] && <a href={`https://wa.me/${props.data[0].phone1}`} className='wp-btn'><RiWhatsappLine /></a>}
            <div onClick={ClickHandler} className='top-btn'><IoIosArrowUp /></div>
        </footer>
    )
}


export default Footer
