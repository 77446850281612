import React from 'react'
import {Row, Col, Container} from 'reactstrap'
import Img1 from '../images/sud.svg'
import Img from '../images/et.svg'
import Img2 from '../images/ekin.svg'
import { useTranslation } from 'react-i18next'
import {Link} from 'react-router-dom'

function HomeProducts() {
  const {t} = useTranslation();
  const data = [
    {
      img: Img,
      title: `${t('et-mehsulu')}`,
      subtitle: `${t('et-subtitle')}`
    },
    {
      img: Img1,
      title: `${t('sud-mehsulu')}`,
      subtitle: `${t('sud-subtitle')}`
    },
    {
      img: Img2,
      title: `${t('ekincilik')}`,
      subtitle: `${t('ekincilik-subtitle')}`
    }
  ]
  return (
    <section id='home-products'>
        <Container>
          <Row>
            {data && data.map((item, index)=>(
              <Col lg='4' key={index}>
                <div className='card'>
                  <Link to="/products" className='card-link'></Link>
                  <img src={item.img} alt=''/>
                  <p className='card-title'>{item.title}</p>
                  <p className='card-subtitle'>{item.subtitle}</p>
                </div>
              </Col>
            ))}
            
          </Row>
        </Container>
    </section>
  )
}


export default  HomeProducts