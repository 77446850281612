import axios from "axios";

export const getData = () => async dispatch => {
    let lang = localStorage.getItem('i18nextLng');
    try{
        const data = await axios 
        .get(`https://admin.xachmazagropark.az/api`, { headers: {
            'Accept-Language':lang
        }})
        .then(res => dispatch ({
            type: "success", payload : res.data
        }))
    }catch (err) {
        dispatch ({
            type: 'error', payload: err
        })
    }
}