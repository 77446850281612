import React from "react";
import { Container } from "reactstrap";
import AnchorLink from "react-anchor-link-smooth-scroll";
import parse from 'html-react-parser'
function Products(props) {
  return (
    <section id="products">
      <div className="about-header">
        <Container>
          {props.products &&
            props.products.map((item) => (
              <AnchorLink href={`#${item.id}`} offset="270" key={item.id}>
                {item.name}
              </AnchorLink>
            ))}
        </Container>
      </div>

      <div className="products-container">
        <Container>
          {props.products &&
            props.products.map((item) => (
              <div className="product" id={item.id} key={item.id}>
                <div className="product-img about-img">
                  <img src={item.image} alt="" />
                </div>
                <div className="product-text">
                  <h2 className="product-title">{item.name}</h2>
                  <div>{parse(item.title)}</div>
                </div>
              </div>
            ))}
        </Container>
      </div>
    </section>
  );
}

export default Products;
