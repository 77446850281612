import React, {useEffect} from 'react'
import Header from '../Components/Header'
import Footer from "../Components/Footer"
import About from "../Components/About"
import { Fragment } from 'react';
import Breadcrumbs from '../Components/Breadcrumbs'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {getData} from '../action'

function AboutPage(props) {
  const {t} = useTranslation()
  useEffect(()=>{
    props.getData()
  },[])

  return (
    <Fragment>
      <Header/>
        {/* {props.data.Contact && <Header data={props.data.Contact}/>} */}
        <Breadcrumbs id={'breadcrumbs-about'} bred={t('breadcrumb-haqqimizda')} title={t('breadcrumb-subtitle')} subtitle={t('haqqimizda')}/>
        {props.data && <About partner={props.data.Partnyorlar}/>}
        {props.data.Contact && <Footer data={props.data.Contact}/>}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    data: state.data
  }
}

export default connect(mapStateToProps, {getData})(AboutPage)