import React, {useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HomeContact from "../Components/HomeContact";
import HomeMenu from "../Components/HomeMenu";
import HomeAbout from "../Components/HomeAbout";
import Home from "../Components/Home";
import HomeProducts from "../Components/HomeProducts";
import {getData} from "../action"
import {connect} from 'react-redux'
function HomePage(props) {
  useEffect(()=>{
    props.getData()
  }, [])
  return (
    <div>
       <Header className={'header-home'}/>
        {/* {props.data.Contact && <Header data={props.data.Contact} />} */}
        {props.data && <Home data={props.data.Slider}/>}
        <HomeProducts/>
        <HomeAbout/>
        <HomeMenu/>
        <HomeContact/> 
        {props.data.Contact && <Footer data={props.data.Contact}/>}
    </div>
  )
}
const mapStateToProps = state => {
  return {
    data: state.data,
  }
}
export default connect(mapStateToProps, {getData})(HomePage)