import React from 'react'
import {Container} from 'reactstrap'
import {FiClock} from 'react-icons/fi'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
function News(props) {
   
  return (
    <section id="news">
        <Container>
            {props.data && props.data.map((item, index)=>(
                <div className='card' key={index}>
                    <Link to={`${item.id}`}  className='card-link'></Link>
                    <div className="card-header">
                        <img src={item.image} alt=""/>
                    </div>
                    <div className='card-body'>
                        <p className="card-title">{item.title}</p>
                        <div className="card-subtitle">{parse(item.desc)}</div>
                        <span className='news-date'><FiClock/> {item.date}</span>
                    </div>
                </div>
            ))}
        </Container>
    </section>
  )
}



export default News
