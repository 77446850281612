import React from 'react'
import {Container} from 'reactstrap'
import Iframe from "react-iframe";
import {BsInstagram} from 'react-icons/bs'
import {BiEnvelope} from 'react-icons/bi'
import {FiFacebook} from 'react-icons/fi'
import {useTranslation} from 'react-i18next'


function Contact(props) {

  const url = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d381914.68889069016!2d48.500989362779414!3d41.59718837859143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4037b2981c75d24b%3A0xc31be601c3389aa0!2zWGHDp21heiwgQXrJmXJiYXljYW4!5e0!3m2!1saz!2s!4v1657960232014!5m2!1saz!2s'

  const {t} = useTranslation()
  return (
    <section id="contact">
        <Container>
            <div className='map'>
              <Iframe src={url}
              width="100%"
              height="100%"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
            </div>
            <div className='contact-info'>
              <h2 className='title'>{t('unvan-title')}</h2>
              <p>{t('unvan')}</p>
              <p>{t('unvan-2')}</p>
              <div className='contact-phone'>
                <a href={`tel:${props.phone1}`}>{props.phone1}</a>
                <a href={`mailto:${props.mail}`}>{props.mail}</a>
              </div>
              <h3>{t('unvan-title-2')}</h3>
              <div className='social-links'>
                <a href={`mailto:${props.mail}`} className='mail'><BiEnvelope/> {t('email')}</a>
                <a href={props.insta} className='insta' target="blank"><BsInstagram/> {t('instagram')}</a>
                <a href={props.fb} className='fb' target="blank"><FiFacebook/> {t('facebook')}</a>
              </div>
            </div>
        </Container>
    </section>
  )
}



export default Contact