import React, { Fragment, useEffect } from 'react'
import Breadcrumbs from '../Components/Breadcrumbs'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Vacancy from '../Components/Vacancy'
import VacancyContact from '../Components/VacancyContact'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {getData} from '../action'
function VacancyPage(props) {
  const {t} = useTranslation()

  useEffect(()=>{
    props.getData()
  },[])

  return (
    <Fragment>
       <Header/>
        {/* {props.data.Contact && <Header data={props.data.Contact}/>} */}
        <Breadcrumbs id={'breadcrumbs-vacancy'} bred={t('breadcrumb-vakansiyalar')} title={t('breadcrumb-subtitle')} subtitle={t('vakansiyalar')}/>
        {props.data.Vakansiya && <Vacancy data={props.data.Vakansiya}/>}
        {props.data.Contact && <VacancyContact data={props.data.Contact}/>}
        {props.data.Contact && <Footer data={props.data.Contact}/>}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    data: state.data
  }
}

export default connect(mapStateToProps, {getData})(VacancyPage)