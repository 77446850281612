import React from 'react'
import {Row, Col, Container} from 'reactstrap'
import Img from '../images/about.jpg'
import {useTranslation} from "react-i18next"
import {Link} from 'react-router-dom'

function HomeAbout() {
  const {t} = useTranslation()
  return (
    <section id='home-about'>
        <Container>
            <Row>
                <Col lg='6'>
                    <p className='subtitle'>{t("haqqimizda-title")}</p>
                    <h2 className='title'>{t("haqqimizda-subtitle")}</h2>
                    <p className='text'>{t("haqqimizda-text")}</p>
                    <Link to="/about" className='button'>{t("button")}</Link>
                </Col>
                <Col lg='6'>
                  <div className='about-img'>
                    <img src={Img} alt=''/>
                  </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default HomeAbout