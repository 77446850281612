import React, { Fragment, useEffect } from 'react'
import Breadcrumbs from '../Components/Breadcrumbs'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import VacancyContact from '../Components/VacancyContact'
import VacancySingle from '../Components/VacancySingle'
import { useTranslation } from 'react-i18next'
import {useParams} from "react-router-dom"
import {getData} from "../action"
import {connect} from 'react-redux'

function VacancySinglePage(props) {
  const {productId} = useParams()
  useEffect(() => {
    props.getData()

}, [])


const thisProduct = props.data.Vakansiya?.find(prod => prod.id === +productId)


  const {t} = useTranslation()
  return (
    <Fragment>
       <Header/>
      {/* {props.data.Contact && <Header data={props.data.Contact}/>} */}
      {thisProduct && 
          <Breadcrumbs id={'breadcrumbs-vacancy'} bred2={t('breadcrumb-vakansiyalar')} title={thisProduct.title_1} bred={thisProduct.title_1} bredLink={'vacancy'}/>
        }
      <VacancySingle data={thisProduct}/>
      {props.data.Contact && <VacancyContact data={props.data.Contact}/>}
      {props.data.Contact && <Footer data={props.data.Contact}/>}
    </Fragment>
  )
}


const mapStateToProps = state => {
  return {
    data: state.data
  }
}
export default connect(mapStateToProps, {getData})(VacancySinglePage)