import React, { Fragment, useEffect } from 'react'
import Breadcrumbs from '../Components/Breadcrumbs'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { useTranslation } from 'react-i18next'
import Products from '../Components/Products'
import {getData} from "../action"
import {connect} from 'react-redux'

function ProductsPage(props) {
    const {t} = useTranslation()
    useEffect(()=>{
      props.getData()
    }, [])
  return (
    <Fragment>
       <Header/>
        {/* {props.data.Contact && <Header data={props.data.Contact}/>} */}
        <Breadcrumbs id={'breadcrumbs-product'} bred={t('breadcrumb-mehsullar')} title={t('breadcrumb-subtitle')} subtitle={t('mehsullar')}/> 
        <Products products={props.data.Mehsullar}/>
        {props.data.Contact && <Footer data={props.data.Contact}/>}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    data: state.data
  }
}
export default connect(mapStateToProps, {getData})(ProductsPage)
