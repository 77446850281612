import React, { useState, useRef } from "react";
import { Container } from "reactstrap";
import { MdClose } from "react-icons/md";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import axios from 'axios'
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form'
import ReCAPTCHA from "react-google-recaptcha";

function VacancySingle(props) {
  const { t } = useTranslation();
  const [modalBox, setModalBox] = useState(false);
  const [form, setform] = useState(false);

  const handleModal = () => {
    setform(true);
    setModalBox(true);
  };
  const handleDismissClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setModalBox(false);
    }
  };

  const inputRef = useRef(null);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = async (data) => {
    let formData = new FormData();    //formdata object
    formData.append("file", data.file[0]);
    formData.append("name", data?.name);
    formData.append("surname", data?.surname);
    formData.append("email", data?.email);
    formData.append("phone", data?.phone);
    formData.append("experience", data?.experience);
    formData.append("gender", data?.gender);
    formData.append("vacancy_id", data?.vacancy_id);
    formData.append("birthdate", data?.birthdate);


    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post("http://api.testjed.me/api/sendresume", formData, config)
      .then(response => {
        toast.success(t("success-message"))
      })
      .catch(error => {
        toast.error(t("error-message"))
      });

    reset()

  };


  return (
    <section className="vacancy-single-section">
      <div id="vacancy-single">
        {props.data && (
          <Container>
            <p className="vacancy-title">{props.data.title_1}</p>
            <div className="vacancy-text">{parse(props.data.desc_1)}</div>
            {props.data.desc_2 && (
              <div>
                <p className="vacancy-title">{t("vacancy-title")}</p>
                <div>{parse(props.data.desc_2)}</div>
              </div>
            )}
            {props.data.desc_3 && (
              <div>
                <p className="vacancy-title">
                  Tələb edilən bilik, bacarıq və təcrübə
                </p>
                <div>{parse(props.data.desc_3)}</div>
              </div>
            )}
            <button className="button" onClick={handleModal}>
              {t("Müraciət et")}
            </button>
          </Container>
        )}

        {modalBox && (
          <div className="modal-container dismiss" onClick={handleDismissClick}>
            <ToastContainer />
            <div className="modal-box">
              <MdClose
                className="dismiss dismiss-btn"
                onClick={handleDismissClick}
              />
              {form && (
                <div>
                  <p className="modal-title">{t("məlumatlar")}</p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                      <label>{t("vacancy-input-1")}</label>
                      <input id="name" placeholder="" {...register('name', { required: true })} />
                      {errors.name && errors.name.type === "required" && <p className='error-message'>{t("error-message-3")}</p>}
                    </div>
                    <div>
                      <label>{t("vacancy-input-2")}</label>
                      <input id="surname" placeholder=" " {...register('surname', { required: true })} />
                      {errors.surname && errors.surname.type === "required" && <p className='error-message'>{t("error-message-4")}</p>}
                    </div>
                    <div>
                      <label>{t("vacancy-input-3")}</label>
                      <Form.Select {...register('gender', { required: true })}>
                        <option value='kisi'>kisi</option>
                        <option value='qadin'>qadin</option>
                      </Form.Select>
                    </div>
                    <div>
                      <label>{t("vacancy-input-4")}</label>
                      <input type='date' {...register('birthdate', { required: true })} placeholder="" />
                    </div>
                    <div>
                      <label>{t("vacancy-input-5")}</label>
                      <Form.Select {...register('experience', { required: true })}>
                        <option value='0-1 il'>0-1 il</option>
                        <option value='1-3 il'>1-3 il</option>
                        <option value='3-5 il'>3-5 il</option>
                      </Form.Select>
                    </div>
                    <div>
                      <label>{t("vacancy-input-6")}</label>
                      <input id="email" placeholder="info@domain.az" {...register('email', { required: true })} />
                      {errors.email && errors.email.type === "required" && <p className='error-message'>{t("error-message-2")}</p>}
                    </div>
                    <div>
                      <label>{t("vacancy-input-7")}</label>
                      <input id="phone" {...register('phone', { required: true, maxLength: 13, minLength: 13 })} defaultValue='+994' />
                      {errors.phone && errors.phone.type === "required" && <div className='error-message'>{t("error-message-5")}</div>}
                      {errors.phone && errors.phone.type === "maxLength" && <div className='error-message'>{t("error-message-6")}</div>}
                      {errors.phone && errors.phone.type === "minLength" && <div className='error-message'>{t("error-message-6")}</div>}
                    </div>
                    <div>
                      <label>
                        {" "}
                        <AiOutlineCloudUpload /> {t("vacancy-input-8")}
                      </label>
                      <input type="file" {...register('file', { required: true })} />
                    </div>

                    <div className="vacancy-form-bottom">
                      <div className="recap">
                        <ReCAPTCHA
                          sitekey="6LeHqFwjAAAAAJfTe1i1KHGLr3RzP4Waqtca0pep"
                        />
                      </div>

                      <div className="vacancy-btn">
                        <button className="button">{t("contact-button")}</button>
                      </div>

                    </div>


                    <input type="hidden" {...register('vacancy_id')} value={props.data.id} />
                  </form>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default VacancySingle;
