import React, { Fragment, useEffect } from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HomeContact from '../Components/HomeContact';
import Contact from '../Components/Contact';
import Breadcrumbs from '../Components/Breadcrumbs';
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {getData} from '../action'

function ContactPage(props) {
  useEffect(()=>{
    props.getData()
  }, [])

  const {t} = useTranslation()
  return (
    <Fragment>
       <Header/>
      {/* {props.data.Contact && <Header data={props.data.Contact}/>} */}
      <Breadcrumbs id={'breadcrumbs-contact'} bred={t('breadcrumb-elaqe')} title={t('breadcrumb-subtitle')} subtitle={t('elaqe')}/>
      {props.data.Contact && <Contact mail={props.data.Contact[0].email} insta={props.data.Contact[0].instalink} fb={props.data.Contact[0].fblink} phone1={props.data.Contact[0].phone1} phone2={props.data.Contact[0].phone2} />}
      <HomeContact/>
      {props.data.Contact && <Footer data={props.data.Contact}/>}
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    data: state.data
  }
}

export default connect (mapStateToProps,{getData})(ContactPage)