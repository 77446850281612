import "bootstrap/dist/css/bootstrap.css"
import './scss/main.css'
import { Fragment } from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ContactPage from './MainComponent/ContactPage'
import AboutPage from './MainComponent/AboutPage'
import HomePage from './MainComponent/HomePage';
import NewsPage from './MainComponent/NewsPage';
import ProductsPage from './MainComponent/ProductsPage';
import GalleryPage from './MainComponent/GalleryPage';
import NewsSinglePage from './MainComponent/NewsSinglePage';
import VacancyPage from "./MainComponent/VacancyPage";
import VacancySinglePage from "./MainComponent/VacancySinglePage";
import './i18n';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage/>} exact/>
          <Route path="contact" element={<ContactPage/>} />
          <Route path="about" element={<AboutPage />}/>
          <Route path="news" element={<NewsPage />}/>
          <Route path="news/:productId" element={<NewsSinglePage />}/>
          <Route path="products" element={<ProductsPage />}/>
          <Route path="gallery" element={<GalleryPage />}/>
          <Route path="vacancy" element={<VacancyPage />}/>
          <Route path="vacancy/:productId" element={<VacancySinglePage />}/>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
