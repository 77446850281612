import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import Img from '../images/vacancy.jpg'
import Img1 from '../images/video.jpg'
import Img2 from '../images/news.jpg'
import Img3 from '../images/gallery.jpg'
import { BsFillPlayFill, BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function HomeMenu() {
  const { t } = useTranslation();

  return (
    <section id='homeMenu'>
      <Container>
        <Row>
          <Col lg='2'>
            <div>
              <Link to="/news" className="card-link" />
              <div className='home-menu-img'>
                <img src={Img2} alt="" />
              </div>
              <p className='menu-title'>{t("home-menu-1")}</p>
              <p className='menu-subtitle'><span>{t("button")}</span><BsArrowRight /></p>
            </div>
            <div>
              <Link to="/vacancy" className="card-link" />
              <div className='home-menu-img'>
                <img src={Img} alt="" />
              </div>
              <p className='menu-title'>{t("home-menu-2")}</p>
              <p className='menu-subtitle'><span>{t("button")}</span><BsArrowRight /></p>
            </div>
          </Col>
          <Col lg='5'>
            <div>
              <Link to="/gallery" className="card-link" />
              <div className='home-menu-img'>
                <img src={Img3} alt="" />
              </div>
              <p className='menu-title'>{t("home-menu-3")}</p>
              <p className='menu-subtitle'><span>{t("button")}</span><BsArrowRight /></p>
            </div>
          </Col>
          <Col lg='5'>
            <div>
              <Link to="/gallery" className="card-link" />
              <div className='home-menu-img'>
                <img src={Img1} alt="" />
                <div className='play'><BsFillPlayFill /></div>
              </div>
              <p className='menu-title'>{t("home-menu-4")}</p>
              <p className='menu-subtitle'><span>{t("button")}</span><BsArrowRight /></p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}




export default HomeMenu