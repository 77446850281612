import React from 'react'
import {Container} from 'reactstrap'
import {FiClock} from 'react-icons/fi'
import parse from 'html-react-parser'
import {Link } from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {AiOutlineArrowRight} from 'react-icons/ai'

function SingleNews(props) {
  const {t} = useTranslation();

  return (
    <section id="single-news">
      {props.data &&
        <Container>
            <div>{parse(props.data.desc)}
            </div>
            <div className='date-div'>
              <span className='date'><FiClock/> {props.data.date}</span>
            </div>
            <div className='butun-xeber'><Link to="/news">{t("butun-xeber")} <AiOutlineArrowRight/></Link></div>
        </Container>
}
    </section>
  )
}



export default SingleNews