import React, { useRef } from 'react'
import { Container } from 'reactstrap'
import { useTranslation } from "react-i18next"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

function HomeContact() {
  const { t } = useTranslation()
  const inputRef = useRef(null);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = data => {
    axios
      .post(
        'http://api.testjed.me/api/message',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(response => toast.success(t("success-message")))
      .catch(error => toast.error(t("error-message")));
    reset()
  };
  return (
    <section id='home-contact'>
      <ToastContainer />
      <Container>
        <p className='subtitle'>{t("contact-title")}</p>
        <h2 className='title'>{t("contact-subtitle")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <input id="name" placeholder=" " {...register('name', { required: true })} />
            {errors.name && errors.name.type === "required" && <p className='error-message'>{t("error-message-1")}</p>}
            <label htmlFor='name'>{t("contact-input-1")}</label>


          </div>
          <div>
            <input id="email" type='email' placeholder=" " {...register('email', { required: true })} />
            {errors.email && errors.email.type === "required" && <p className='error-message'>{t("error-message-2")}</p>}
            <label htmlFor='email'>{t("contact-input-2")}</label>
          </div>
          <div>
            <textarea name="description" id='message' defaultValue='' placeholder=" " {...register("msj")}></textarea>
            <label htmlFor='message'>{t("contact-input-3")}</label>
          </div>
          <ReCAPTCHA
            sitekey="6LeHqFwjAAAAAJfTe1i1KHGLr3RzP4Waqtca0pep"

          />
          <button className='form-btn'>{t("contact-button")}</button>

        </form>
      </Container>
    </section>
  )
}


export default HomeContact
