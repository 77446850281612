import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {Container} from 'reactstrap'


function Breadcrumbs(props) {
  const {t} = useTranslation()
  return (
    <div className='breadcrumbs' id={props.id}>
        <Container>
            <ul>
                <li><Link to="/">{t('breadcrumb-ana')}</Link></li>
                {props.bred2 && <li><Link to={`/${props.bredLink}`}>{props.bred2}</Link></li>}
                <li>{props.bred}</li>
            </ul> 
            <div className='bred-text'>
                {props.subtitle && <p className='bred-subtitle'>{props.subtitle}</p> } 
                <p className='bred-title'>{props.title}</p>    
            </div> 
        </Container>
    </div>
  )
}


export default Breadcrumbs