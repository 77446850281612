import React from "react";
import { Row, Col, Container } from "reactstrap";
import Img from "../images/about.jpg";
import { useTranslation } from "react-i18next"
import AnchorLink from 'react-anchor-link-smooth-scroll'

function About(props) {
  const {t} = useTranslation()
  
  return (
    <section id="about-page">
      <div className="about-header">
        <Container>
          <AnchorLink href='#about' offset='270'>{t('about-link-1')}</AnchorLink>
          <AnchorLink href='#fealiyyet' offset='270'>{t('about-link-2')}</AnchorLink>
          <AnchorLink href='#partner' offset='270'>{t('about-link-3')}</AnchorLink>
        </Container>
      </div>
      <div id="about">
        <Container>
          <p>
            {t('about-text-1')}
          </p>
          <p>
            {t('about-text-2')}
          </p>
          <p>
            {t('about-text-3')}
          </p>
        </Container>
      </div>
      <div id="fealiyyet">
        <Container>
          <Row>
            <Col lg="6">
              <h2 className="title">{t('about-title-1')}</h2>
              <p className="text">
                {t('about-text-4')}
              </p>
            </Col>
            <Col lg="6">
              <div className="about-img">
                <img src={Img} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="partner">
        <Container>
          <h2 className="title">{t("about-title-2")}</h2>
          <div className="partner-img">
            {props.partner &&
              props.partner.map((item, index) => <img key={index} src={item.image} alt="" />)}
          </div>
        </Container>
      </div>
    </section>
  );
}

export default About;
