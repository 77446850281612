import React from 'react'
import { Link } from 'react-router-dom'
import {Container} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import parse from 'html-react-parser'
import {BsArrowRightShort} from 'react-icons/bs'
function Vacancy(props) {
    const {t} = useTranslation()
    

  return (
    <section id="vacancy">
        <Container>
            <div className='vacancy-list'>
                {props.data && props.data.map(item => (
                    <div className='vacancy-item' key={item.id}>
                        <Link to={`${item.id}`} className='card-link'></Link>
                        <h3 className='vacancy-title'>{item.title}</h3>
                        <Link to='' className='vacancy-btn'><span><BsArrowRightShort/></span>{t("button")}</Link>
                        <div className='vacancy-subtitle'>{parse(item.desc_1)}</div>
                        <div className='vacancy-date'><span>{item.end_date}</span> {t("bitme-vaxti")}</div>
                    </div>
                ))}
            </div>
        </Container>
    </section>
  )
}


export default Vacancy